import { FaTshirt } from 'react-icons/fa';
import { IoEarth } from 'react-icons/io5';
import { FaHome } from 'react-icons/fa';
import { BiNews } from 'react-icons/bi';
import { CiBank } from 'react-icons/ci';
import SponsorshipCard from '../cards/SponsorshipCard';
import styles from './SponsorshipGrid.module.css';

const SponsorshipGrid = () => {

    return(
        <div className={styles.wrapper}>
            <h3>Oferta Sponsorska</h3>
            <div className={styles.desc}>
                <h3>Wszelkie propozycje związane ze sponsoringiem są do indywidualnego uzgodnienia</h3>
                <h3>W ramch umowy sponsorskiej oferujemy: </h3>
            </div>
            <div className={styles.box}>
                <SponsorshipCard
                    icon={<FaTshirt/>}
                    title='Reklamę'
                    desc='Ubrania klubowe dla zawodników, naklejki na wiosła oraz kajaki z logo wspierających nas firm'
                />
                <SponsorshipCard
                    icon={<IoEarth/>}
                    title='Globalny zasięg reklamy'
                    desc='Co roku nasi zawodnicy statrują nie tylko na imprezach w całej    Polsce ale również na zawodach międzynarodowych często organizowanych  poza granicami naszego kraju'
                />
                <SponsorshipCard
                    icon={<FaHome/>}
                    title='Miejsce na reklamę'
                    desc='Siedziba naszego klubu znajduje się w centrum ruchu   turystycznego, gdzie w ciągu sezonu letniego przewija się nawet kilka     tysięcy turystów dziennie'
                />
                <SponsorshipCard
                    icon={<BiNews/>}
                    title='Social Media'
                    desc='Oznaczanie firm w postach wrzucanych na oficjalną strone klubu,   facebooka oraz instagrama'
                />
            </div>
            <h3 style={{marginTop: '25px', marginBottom: '25px'}}>wspieraj nas</h3>
            <div className={styles.supp}>
                <h3>Także Ty możesz wesprzeć klub przekazując 1,5% podatku lub wpłacając darowiznę. Dzięki twojej pomocy klub zyskuje cenne fundusze na sprzęt sportowy, wyjazdy i obozy, dzieki czemu zawodnicy maja jeszcze większe możliwości!</h3>
                <div className={styles.bank}>
                    <div>
                        <p>1.5%</p>
                        <div className={styles.box2}>
                            <h3>KRS 0000040632</h3>
                            <h3>"Fundacja Dzieci Pienin" z dopiskiem KS Pieniny</h3>
                        </div>
                    </div>
                    <div>
                        <p><CiBank/></p>
                        <div className={styles.box2}>
                            <h3>PKO BP S.A / Szczawnica</h3>
                            <h3>39 1020 3466 0000 9402 0086 9347</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SponsorshipGrid;