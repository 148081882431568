import SponsorshipGrid from '../../components/grids/SponsorshipGrid';
import Banner from '../../components/banner/Banner';
import photo from '../../images/4.jpg';
import styles from './SponsorsPage.module.css';
import { useEffect } from 'react';

const SponsorsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "KS Pieniny Szczawnica | Sponsorzy";
    },[])

    return(
        <div className={styles.wrapper}>
            <Banner
                link={photo}
                title='sponsorzy'
                long='160'
            />
            <SponsorshipGrid/>
        </div>
    )

}

export default SponsorsPage;